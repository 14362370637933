import React from "react";
import { Heading } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
  const {t} = useTranslation();
  return (
    <Heading>{t('text_not_found')}</Heading>
  );
};
