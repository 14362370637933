import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter,RouterProvider,createRoutesFromElements,Route,Navigate } from "react-router-dom";

import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate.js";


import { AuthenticationGuard } from "./components/authentication-guard.js";
import { CallbackPage } from "./pages/CallbackPage.jsx";
import { HomePage } from "./pages/HomePage.jsx";
import { NotFoundPage } from "./pages/NotFound.jsx";
import { Dashboard } from "./pages/Dashboard.jsx";
import { Floors } from "./pages/Floors.jsx";
import { sensorDataLoader } from "./pages/Dashboard.jsx";
import { floorDataLoader } from "./pages/Floors.jsx";

import { ChakraProvider } from "@chakra-ui/react";
import { IndoorAirPage } from "./pages/InDoorAirPage.jsx";
import { DisabledToiletsPage } from "./pages/DisabledToiletsPage.jsx";
import { RootTopExitsPage } from "./pages/RootTopExitsPage.jsx";
import { WaterLeaksPage } from "./pages/WaterLeaksPage.jsx";
import { MotionDetectPage } from "./pages/MotionDetectPage.jsx";
import { WashingMachinePage } from "./pages/WashingMachinePage.jsx";
import { DryerPage } from "./pages/DryerPage.jsx";
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n.js"

const container = document.getElementById("root");
const root = createRoot(container);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Auth0ProviderWithNavigate/>}>
      <Route path="/" element={<AuthenticationGuard component={HomePage}/> }>
        <Route index  loader={sensorDataLoader} element={<AuthenticationGuard component={Dashboard} />}
        />
        <Route path="/dashboard" loader= {sensorDataLoader} element={<AuthenticationGuard component={Dashboard}
          errorElement= {<NotFoundPage />}/>}></Route>
            <Route path="/dashboard/indoorair"  element={<AuthenticationGuard component={IndoorAirPage}
              errorElement= {<NotFoundPage />}/>}></Route>
            <Route path="/dashboard/disabledtoilets"  element={<AuthenticationGuard component={DisabledToiletsPage}
              errorElement= {<NotFoundPage />}/>} ></Route>  
            <Route path="/dashboard/rooftopexits"  element={<AuthenticationGuard component={RootTopExitsPage}
              errorElement= {<NotFoundPage />}/>} ></Route>  
            <Route path="/dashboard/waterleakages"  element={<AuthenticationGuard component={WaterLeaksPage}
              errorElement= {<NotFoundPage />}/>} />
            <Route path="/dashboard/twentyfifthfloormotion"  element={<AuthenticationGuard component={MotionDetectPage}
              errorElement= {<NotFoundPage />}/>} />
            <Route path="/dashboard/washingmachine"  element={<AuthenticationGuard component={WashingMachinePage}
              errorElement= {<NotFoundPage />}/>} />
              <Route path="/dashboard/dryer"  element={<AuthenticationGuard component={DryerPage}
              errorElement= {<NotFoundPage />}/>} 
  
        />

        <Route path="/floors" loader = {floorDataLoader} element={<AuthenticationGuard component={Floors}
          errorElement= {<NotFoundPage />}/>}
        />
      </Route>
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />      
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </ChakraProvider>
  </React.StrictMode>
);
