import React from 'react'
import { Button } from '@chakra-ui/react'
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';

export default function LogoutButton() {
  const {t} = useTranslation();

  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  return (
    <Button colorScheme="orange" onClick={handleLogout}>{t('text_logout')}</Button>
  )
}
