import { Card, Box, Icon, Text, SimpleGrid} from '@chakra-ui/react';
import { LuLeaf } from "react-icons/lu";
import { TbDisabled } from "react-icons/tb";
import { ImExit } from "react-icons/im";
import * as signalR from "@microsoft/signalr";
import { useLoaderData,useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';



export const Dashboard = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const connection = new signalR.HubConnectionBuilder()
        .withUrl("http://localhost:6060/devicehub")
        .build();

    const result = useLoaderData();
   // deviceType
   // 0 - Air Quality
   // 1 - Motion Detection
   // 2 - Door Sensor 
   // 3 - Fall Detection
   // 4 - Water Leakage
   // 5 - Energy Meter (washing machine)
   // 6 - Dryer


   //<Box mt='20px'>
   //<WashingMachineInUseStatus devices = {washingMachineInUse}/>
   //<Box mt='20px'></Box>
   //<LeakStatus devices = {waterLeakage}/>
//</Box>

    const airQuality = result.filter((e) => {
        if(e.deviceType === 0 ) {
            return true;

        }else {
            return false;
        }
    });

    const poorAirQualityCount = airQuality.filter((e) => {
        if(e.status === '1'){
            return true;
        }else {
            return false;
        }
    });

    const disableToilet = result.filter((e) => {
        if(e.deviceType === 3) {
            return true;
        }else {
            return false;
        }
        
    });
    
    const toiletFallDetectionCount = disableToilet.filter((e) => {
        if(e.status === '1'){
            return true;
        }else { 
            return false;
        }

    });
            
    const roofTopExit = result.filter((e) => {
        if( e.deviceType === 2) {
            return true;
        }else {
            return false;
        }    
    });
    const motionDetected = result.filter((e) => {
        if( e.deviceType === 1 && e.status === '1') {
            return true;
        }else {
            return false;
        }    
    });


    const roofTopMotionCount = roofTopExit.filter((e) => {
            if(e.status === '1'){
                return true;
            }else { 
                return false;

            }
    });
    

    const waterLeakage = result.filter((e) => {
       if( (e.deviceType === 4) && (e.status === '1')) {
        return true;
       } else {
        return false;
       }      
    }); 


    const washingMachineInUse = result.filter((e) => {
        if(e.deviceType === 5 && e.status === '1') {
            return true;
        }else {
            return false;
        }    
    });   
    const dryerInUse = result.filter((e) => {
        if(e.deviceType === 6 && e.status === '1') {
            return true;
        }else {
            return false;
        }    
    });   


    const  onClickIndoorAirQ=()=>navigate("/dashboard/indoorair",{state:airQuality}); 
    const  onClickDisabledToilets=()=>navigate("/dashboard/disabledtoilets",{state:disableToilet}); 
    const  onClickRoofTopExits=()=>navigate("/dashboard/rooftopexits",{state:roofTopExit}); 
    const  onClickWaterLeakages=()=>navigate("/dashboard/waterleakages",{state:waterLeakage}); 
    const  onClick25thFloorMotion=()=>navigate("/dashboard/twentyfifthfloormotion",{state:motionDetected});
    const  onClickWashingMachine=()=>navigate("/dashboard/washingmachine",{state:washingMachineInUse});
    const  onClickDryer=()=>navigate("/dashboard/dryer",{state:dryerInUse});
    
    
    return (
    
        <Box mt="20px" mx='20px'>
            <SimpleGrid columns={[1,1,1,4,4]} spacing='40px'>
             
                <Card onClick={onClickIndoorAirQ} w='full' h='150px' backgroundColor='gray.50' _hover={{ bg: "gray.100" }} >
                    <Box display='flex' alignItems='center' align='center' pt="2" ml="4">
                        <Icon as={LuLeaf}  boxSize={4}/>
                        <Text ml="1" fontSize={['lg','lg','lg','lg']}>{t('text_indoor_air_quality')}</Text>  
                    </Box>
                    <Box align="center">
                        { poorAirQualityCount.length > 0 ? (<Text mt="4" color="red.500" fontSize={['lg','lg','2xl','2xl']}>{t('text_poor')}</Text>  )
                        : (<Text mt="4" color="green.500" fontSize={['lg','lg','2xl','2xl']}>{t('text_normal')}</Text> )}
                    </Box>
                </Card>
            
                <Card onClick={onClickDisabledToilets}w='full' h='150px' backgroundColor='gray.50'  _hover={{ bg: "gray.100" }} >
                    <Box display='flex' alignItems='center' align='center' pt="2"  ml="4">
                        <Icon as={TbDisabled} boxSize={4}/>
                        <Text ml="1"  fontSize={['lg','lg','lg','lg']}>{t('text_disabled_toilet')}</Text>
                    </Box>
                    <Box align="center">
                        {toiletFallDetectionCount.length > 0 ? (<Text mt="4" color="red.500" fontSize={['lg','lg','2xl','2xl']}>Fall Detected</Text>)
                            : (<Text mt="4" color="green.500" fontSize={['lg','lg','2xl','2xl']}>{t('text_normal')}</Text>)}
                    </Box>
                </Card>
            
                <Card onClick={onClickRoofTopExits} w='full' h='150px' backgroundColor='gray.50' _hover={{ bg: "gray.100" }}>
                    <Box display='flex' alignItems='center' align='center' pt="2" ml="4">
                        <Icon as={ImExit} boxSize={4} />
                        <Text fontSize={['lg','lg','lg','lg']}>{t('text_roof_exit')}</Text>
                    </Box>
                    <Box align="center">
                        {roofTopMotionCount.length > 0 ? (<Text mt="4" color="red.500" fontSize={['lg','lg','2xl','2xl']}>Motion Detected</Text>)
                        :(<Text mt="4" color="green.500" fontSize={['lg','lg','2xl','2xl']}>{t('text_normal')}</Text>)}
                    </Box>
                </Card>
                <Card onClick={onClick25thFloorMotion} w='full' h='150px' backgroundColor='gray.50' _hover={{ bg: "gray.100" }}>
                    <Box display='flex' alignItems='center' align='center' pt="2" ml="4">
                        <Icon as={ImExit} boxSize={4} />
                        <Text fontSize={['lg','lg','lg','lg']}>{t('text_25F_motion')}</Text>
                    </Box>
                    <Box align="center">
                        {motionDetected.length > 0 ? (<Text mt="4" color="red.500" fontSize={['lg','lg','2xl','2xl']}>Motion Detected</Text>)
                        :(<Text mt="4" color="green.500" fontSize={['lg','lg','2xl','2xl']}>{t('text_normal')}</Text>)}
                    </Box>
                </Card>                
            </SimpleGrid>
            <Box mt="50px" >
            <SimpleGrid columns={[1,1,1,3,3]} spacing='40px'>
                
                    <Card onClick={onClickWaterLeakages} w='full' h='150px' backgroundColor='gray.50' _hover={{ bg: "gray.100" }}>
                        <Box display='flex' alignItems='center' align='center' pt="2" ml="4">
                            <Text fontSize={['lg','lg','lg','lg']}>{t('text_water_leakages')}</Text>
                        </Box>
                        <Box align="center">
                          
                        <Text mt="4" color="red.500" fontSize={['3xl','3xl','4xl','4xl']}>{waterLeakage.length}</Text>
                        </Box>
                    </Card>
                
               
                    <Card onClick={onClickWashingMachine} w='full' h='150px' backgroundColor='gray.50' _hover={{ bg: "gray.100" }} >
                        <Box display='flex' alignItems='center' align='center' pt="2" ml="4"> 
                            <Text fontSize={['lg','lg','lg','lg']}>{t('text_washinig_machines_in_use')}</Text>
                        </Box>
                        <Box align="center">
                            <Text mt="4" color="green.500" fontSize={['3xl','3xl','4xl','4xl']}>{washingMachineInUse.length}</Text>
                        </Box>
                    </Card>
                
                
                    <Card onClick={onClickDryer} w='full' h='150px' backgroundColor='gray.50' _hover={{ bg: "gray.100" }}>
                        <Box display='flex' alignItems='center' align='center' pt="2" ml="4">
                            <Text fontSize={['lg','lg','lg','lg']}>{t('text_dryers_in_use')}</Text>
                        </Box>
                        <Box align="center">
                           <Text mt="4" color="green.500" fontSize={['3xl','3xl','4xl','4xl']}>{dryerInUse.length}</Text>
                        </Box>
                    </Card>
                             
            </SimpleGrid>
            </Box>
        </Box>

    
    )
}
//"http://localhost:6060/Devices/getDevicesWithAlertOn"
const domain = process.env.REACT_APP_API_SERVER_URL;
const getSensorDataApi = domain +"/Devices/GetDevicesOnAllFloors";
export const  sensorDataLoader = async () => {
    const res = await fetch(getSensorDataApi);
    const result = await res.json();
    return result
}

