import { Box,SimpleGrid,Stack,Text,Select, HStack } from '@chakra-ui/react';
import { useLoaderData,redirect, useLocation } from 'react-router-dom';
import { DryerDevice } from '../components/DryerDevice.jsx';

export const DryerPage = () => {
    const    location = useLocation();
    const  waterLeaks = location.state;
    return(
        <Box mt="20px" ml='20px' mr='20px'>
            <Text mb='20px' ml='5px' fontSize='sm'></Text>
            <SimpleGrid columns={[1,1,2,2,3,3,4]} spacing='10px'>
                {waterLeaks.map((e)=> 
                <DryerDevice key={e.deviceId} device = {e }  />)}
            </SimpleGrid>
        </Box>
    )
}

//"http://localhost:6060/Devices/getDevicesWithAlertOn"
const domain = process.env.REACT_APP_API_SERVER_URL;
const getSensorDataApi = domain +"/Devices/getDevicesWithAlertOn";
export const  indoorSensorDataLoader = ()=> redirect("/indoorsensor");
//async () => {
//    const res = await fetch(getSensorDataApi);
//    const result = await res.json();
//    return result
//}
